.login-form {
    max-width: 600px;
    margin: auto;
    height: 100%;
}

.rtl {
    direction: rtl;

}

.resize {
    height: 100vh;
}

.bg-grey {
    background-color: lightgray;
}

.hover:hover {
    background-color: #f6f6f6;
}

.cursor_pointer:hover {
    cursor: pointer;
}

.filter_button {
    background-color: #C7CB85;
}


@media screen and (max-width: 600px) {
    .login-form {
        max-width: 400px;
        margin: auto;
    }
}

@media screen and (max-width:767px) {
    .responsive_width {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .responsive_exam {
        width: 50%;
        margin: auto;
    }
}